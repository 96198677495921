<template>
  <v-toolbar-items class="hidden-md-and-down">
    <v-btn
      text
      v-for="item in menuItems"
      :key="item.title"
      :to="item.path"
      class="font-weight-bold"
      :class="{
        'text-body-1': $vuetify.breakpoint.mdAndDown,
        'text-body-1': $vuetify.breakpoint.lgAndUp,
      }"
    >
      {{ item.title }}
    </v-btn>
  </v-toolbar-items>
</template>

<script>
export default {
  name: "DefaultMenu",
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
